@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{

  *{
    @apply overscroll-none;
  }

    .partial-border::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 20%;
        width: 60%;
        height: 2px;
        background-color: #DD5AFB;
      }

      .gradient-text {
        background: linear-gradient(90deg, #DD5AFB, #6F07FD);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }

      .gradient-border{
        border: 2px solid transparent;
        padding: 1px;
        background-image: linear-gradient(#17052e, #17052e), 
                          linear-gradient(to right, #DD5AFB, #6F07FD);
        background-origin: border-box;
        background-clip: content-box, border-box;
    }

      .prevent-select{
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .slide-transition{
      transition: 0.2s ease;
    }

    .slide-left{
      transform: translateX(-50%);
    }

    .slide-right{
      transform: translateX(0%);
    }

    .modal-bg{
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(10px);
  }

.slideInNav {
    transition: transform 300ms ease;
}
}